























































































import { Component, Vue } from 'vue-property-decorator'
import symbolService, { SymbolPriceAlert } from '@/services/symbolService'
import { AlertTypeInfo, MessageText, PriceAlert } from '@/services/data'
import SymbolName from '@/components/SymbolName.vue'

@Component({
  components: {
    SymbolName
  }
})
export default class Account extends Vue {
  isLoading = false
  alerts: SymbolPriceAlert = { Symbol: [], Option: [], Name: '' }
  message: MessageText = null

  async mounted () {
    await this.loadData()
  }

  async loadData () {
    const res = await symbolService.getAllAlertPricesAsync()
    if (res.Result) {
      res.Result.Symbol.forEach(q => q.Alerts.sort((a, b) => a.AlertPrice - b.AlertPrice))
      res.Result.Option.forEach(q => q.Alerts.sort((a, b) => a.AlertPrice - b.AlertPrice))
      this.alerts = res.Result
    } else {
      await this.$alert(res.Error)
    }
  }

  async cancelChanges () {
    await this.loadData()
  }

  async saveChanges () {
    const req: { AlertInfo: AlertTypeInfo; PriceAlerts: PriceAlert[] }[] = []
    this.alerts.Symbol.forEach(p => {
      req.push({
        AlertInfo: {
          type: 'Stock',
          symbolId: p.Symbol.SymbolId
        },
        PriceAlerts: p.Alerts
      })
    })
    this.alerts.Option.forEach(p => {
      req.push({
        AlertInfo: {
          type: 'Option',
          optionId: p.Option.OptionId
        },
        PriceAlerts: p.Alerts
      })
    })
    const res = await symbolService.addOrUpdateBatchAlertPrice(req)
    if (res.Result) {
      await this.loadData()
    } else {
      await this.$alert(res.Error)
    }
  }

  deleteItem (item: PriceAlert) {
    item.IsDeleting = true
  }
}
